import React, { useState } from 'react';

const PrevLocationContext = React.createContext();

const PrevLocationProvider = ({ children }) => {
  const [prevLocation, setPrevLocation] = useState('/');
  return (
    <PrevLocationContext.Provider value={{ prevLocation, setPrevLocation }}>
      {children}
    </PrevLocationContext.Provider>
  );
};

export { PrevLocationContext, PrevLocationProvider };
