import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { BackgroundContext } from '../contexts/backgroundContext';
import Opening from './Opening';

const SiteWrapper = ({ children }) => {
  const backgroundContext = useContext(BackgroundContext);
  const [showPage, setShowPage] = useState(false);

  const hideOpening = () => {
    setShowPage(true);
  };

  return (
    <>
      <div />
      <Opening hide={showPage} hideHandler={hideOpening} />
      <Page
        style={{
          display: showPage ? 'block' : 'none',
          background: backgroundContext?.backgroundColor,
        }}
      >
        {children}
      </Page>
    </>
  );
};

export default SiteWrapper;

const Page = styled.div`
  transition: background 300ms;
`;
