import React from 'react';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

import './src/styles/fonts.scss';
import './src/styles/typography.scss';

import { PrevLocationProvider } from './src/contexts/prevLocationContext';
import { OverlayProvider } from './src/contexts/overlayContext';
import { BackgroundProvider } from './src/contexts/backgroundContext';
import SiteWrapper from './src/components/SiteWrapper';

Sentry.init({
  dsn: 'https://e9938e79250b46bf9046e1d5732e4ae5@sentry.io/1757828',
});

LogRocket.init('iuw4ok/lisa-morts-makeup');

LogRocket.getSessionURL(sessionURL => {
  Sentry.configureScope(scope => {
    scope.setExtra(`sessionURL`, sessionURL);
  });
});

export const wrapRootElement = ({ element }) => (
  <PrevLocationProvider>
    <OverlayProvider>
      <BackgroundProvider>
        <SiteWrapper>{element}</SiteWrapper>
      </BackgroundProvider>
    </OverlayProvider>
  </PrevLocationProvider>
);
