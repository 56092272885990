import React, { useState } from 'react';

const BackgroundContext = React.createContext();

const BackgroundProvider = ({ children }) => {
  const [backgroundColor, setBackgroundColor] = useState('#F8F3F3');
  return (
    <BackgroundContext.Provider value={{ backgroundColor, setBackgroundColor }}>
      {children}
    </BackgroundContext.Provider>
  );
};

export { BackgroundContext, BackgroundProvider };
