import React, { useState } from 'react';

const OverlayContext = React.createContext();

const OverlayProvider = ({ children }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  return (
    <OverlayContext.Provider value={{ showOverlay, setShowOverlay }}>
      {children}
    </OverlayContext.Provider>
  );
};

export { OverlayContext, OverlayProvider };
