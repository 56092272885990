import React, { useEffect } from 'react';
import styled from 'styled-components';
import Logo from './Logo';

const Opening = ({ hide, hideHandler }) => {
  useEffect(() => {
    setTimeout(() => {
      hideHandler();
    }, 1500);
  });

  return (
    <Container hide={hide} onClick={hideHandler} onWheel={hideHandler}>
      <LogoContainer hide={hide}>
        <Logo color="#FFFFFF" height="56" />
      </LogoContainer>
      <TopLeft hide={hide}>
        <svg
          viewBox="0 0 695 328"
          fill="#F6C4B2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M695 0H0V325.73C9.70726 327.205 19.5151 328 29.323 328C194.333 328 380.182 282.588 417.752 253.18C428.818 244.519 442.23 235.406 456.517 225.699C490.738 202.448 529.977 175.788 554.008 143.751C566.634 126.919 585.576 113.083 605.898 98.2398C639.091 73.9953 675.964 47.063 695 0Z" />
        </svg>
      </TopLeft>
      <TopRight hide={hide}>
        <svg
          viewBox="0 0 838 438"
          fill="#EBCDC5"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M838 437.12V0H0.901367C1.12896 0.440337 1.36266 0.878386 1.60279 1.31393C13.5199 22.6654 61.2047 67.6436 84.5419 86.248C107.816 104.786 278.221 198.472 305.13 212.755C332.04 227.038 508.462 316.837 534.235 323.219C545.924 326.149 584.019 345.1 626.171 366.069C676.719 391.214 733.099 419.26 756.772 426.062C783.592 433.769 809.691 435.497 838 437.12Z" />
        </svg>
      </TopRight>
      <BottomRight hide={hide}>
        <svg
          viewBox="0 0 460 321"
          fill="#F8F3F3"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M460 0.164933V321H0C38.6917 256.764 101.846 206.193 163.079 157.161C195.67 131.064 227.716 105.402 255.24 78.3489C308.942 25.5633 389.596 -2.42473 460 0.164933Z" />
        </svg>
      </BottomRight>
      <BottomLeft hide={hide}>
        <svg
          viewBox="0 0 811 457"
          fill="#E2B3AA"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M35.9588 2.80118e-08C38.4178 7.05147e-05 40.7116 0.133167 42.8292 0.4067C63.5727 3.08616 82.7731 4.9886 100.836 6.77838C150.818 11.7307 192.093 15.8204 233.26 33.1216C251.016 40.5537 273.102 48.3639 296.179 56.525C345.916 74.1137 400.262 93.3325 425.82 113.91C463.425 143.893 477.383 149.926 515.851 165.962C536.466 174.574 581.609 205.202 626.037 235.344C664.777 261.627 702.974 287.542 723.891 298.17C753.12 313.019 784.02 378.96 811 457H0V5.71025C14.0865 2.05296 26.2974 -0.000276914 35.9588 2.80118e-08Z" />
        </svg>
      </BottomLeft>
    </Container>
  );
};

export default Opening;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  cursor: s-resize;
  background: ${({ hide }) => (hide ? 'transparent' : '#ecd9d4')};
  transition: background 500ms;
  
  pointer-events: ${({ hide }) => (hide ? 'none' : 'all')};
  /* opacity: ${({ hide }) => (hide ? '0' : '1')}; */
  `;

const LogoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  /* transform: translateY(-1rem); */
  justify-content: center;
  pointer-events: none;
  z-index: 5;
  transition: opacity 1000ms;

  opacity: ${({ hide }) => (hide ? '0' : '1')};
`;

const TopLeft = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 40vh;
  width: 50vw;
  /* background: #f6c4b2; */
  z-index: 6;
  display: flex;
  align-items: flex-start;
  will-change: transform;
  transition: transform 1000ms;
  transform: ${({ hide }) => hide && 'translate3D(-100%, -100%, 0)'};

  svg {
    display: block;
    width: 100%;
  }
`;

const TopRight = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 50vh;
  width: 60vw;
  /* background: #ebcdc5; */
  display: flex;
  align-items: flex-start;
  will-change: transform;
  transition: transform 1000ms;
  transform: ${({ hide }) => hide && 'translate3D(100%, -100%, 0)'};

  svg {
    display: block;
    width: 100%;
  }
`;

const BottomRight = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  height: 50vh;
  width: 30vw;
  /* background: #f8f3f3; */
  will-change: transform;
  transition: transform 1000ms;
  display: flex;
  align-items: flex-end;
  transform: ${({ hide }) => hide && 'translate3D(100%, 100%, 0)'};

  svg {
    display: block;
    width: 100%;
    /* width: 150%; */
    /* transform: translate(-30%, 0); */
  }
`;

const BottomLeft = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 50vh;
  width: 60vw;
  display: flex;
  align-items: flex-end;
  will-change: transform;
  transition: transform 1000ms;
  transform: ${({ hide }) => hide && 'translate3D(-100%, 100%, 0)'};

  svg {
    display: block;
    width: 100%;
    /* width: 150%; */
    /* transform: translate(-30%, 0); */
  }
`;
